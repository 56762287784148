<template>
  <el-row id="home">
    <el-col :span="24" v-if="live">
      <h1>{{ $t('banner.raised') }}:</h1>
      <h2>&euro;367,98</h2>
    </el-col>
    <el-col :span="24" v-else>
      <h1>{{ $t('banner.timeTill') }}:</h1>
      <h2>
        <vue3-flip-countdown
          :mainColor="'#800816'"
          :secondFlipColor="'#7e111e'"
          :mainFlipBackgroundColor="'#111111'"
          :secondFlipBackgroundColor="'#282828'"
          :deadline="'2022-04-19 19:00:00'"
          :labels="{
            days: 'DD',
            hours: 'HH',
            minutes: 'mm',
            seconds: 'ss'
          }"
        />
      </h2>
    </el-col>
    <el-col :span="24" class="arrow">
      <a href="#planning">ᐯ</a>
    </el-col>
    <div class="confetti">
      <div class="dot" v-for="n in 200" :key="n"></div>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'Banner',
  data() {
    return {
      live: false
    }
  }
}
</script>
