<template>
  <el-row id="planning">
    <el-col :xs="22" :sm="14" :offset="5" class="title">
      <h1>{{ $t('planning.planning') }}</h1>
    </el-col>
    <el-col :xs="22" :sm="14" :offset="5" class="stripe tableTitle">
      <el-row>
        <el-col :span="8">{{ $t('planning.time') }}</el-col>
        <el-col :span="16" class="">{{ $t('planning.activity') }}</el-col>
      </el-row>
    </el-col>
    <el-col :xs="22" :sm="14" :offset="5">
      <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            :name="index"
            v-for="(value, index) in (
              $i18n.locale == 'nl' ? 
              dutchItems : englishItems
              )" :key="value" 
            :class="index % 2 == 1 ? 'stripe' : ''"
          >
            <template #title>
              <el-row class="activity">
                <el-col :span="8">{{ value.time }}</el-col>
                <el-col :span="16" class="">{{ value.activity }}</el-col>
              </el-row>
            </template>
            <div>{{ value.description }}</div>
          </el-collapse-item>
      </el-collapse>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Planning',
  data() {
    return {
      activeName: "",
      dutchItems: [
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
      ],
      englishItems: [
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
        { time: "12:45 - 13:15", activity: "Opening", description: "Lorem ipsum"},
      ]

    };
  }
}
</script>
