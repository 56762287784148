export default {
  "navbar": {
    "charityDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charity day"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])}
  },
  "banner": {
    "raised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raised"])},
    "timeTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time till the charity day"])}
  },
  "planning": {
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])}
  },
  "goals": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals"])},
    "CSGU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre of Sexual Abuse Utrecht"])},
    "CSGUtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre of Sexual Abuse Utrecht offers help to everyone that experienced an unwanted sexual experience. For this, contact can be made safely and anonymously, by telephone or via chat. The Centre of Sexual Abuse Utrecht can call in forensic, medical and psychological help to provide specialist care to victims of recent assault and rape. They are available 24/7 to give you the help you need!"])},
    "milieudefensie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieudefensie"])},
    "milieudefensieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieudefensie is one of the most influential climate organizations at the moment. They argue for a fair and climate-friendly society, through the cooperation of everyone; citizens, but also companies and politicians. For example, Milieudefensie has ensured that Shell has to become more sustainable from the courts. To ensure that the major polluters really contribute to a solution, Milieudefensie needs your help!"])},
    "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find more information at"])}
  }
}