<template>
  <div id="app">
    <Navbar/>
    <Banner/>
    <Planning/>
    <Goals/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Banner from './components/Banner.vue'
import Planning from './components/Planning.vue'
import Goals from './components/Goals.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Navbar,
    Banner, 
    Planning,
    Goals,
    Footer
  }
}
</script>

<style lang="scss">
@import "assets/index.scss";
</style>
