<template>
  <el-row id="footer">
    <el-col :span="12">
      {{ $t('navbar.charityDay') }}
    </el-col>
    <el-col :span="12" class="sticky">
      SV Sticky
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
