export default {
  "navbar": {
    "charityDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goede doelen dag"])},
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])}
  },
  "banner": {
    "raised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgehaald"])},
    "timeTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd tot de goede doelen dag"])}
  },
  "planning": {
    "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])}
  },
  "goals": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doelen"])},
    "CSGU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum Sexueel Geweld Utrecht"])},
    "CSGUtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum Seksueel Geweld Utrecht biedt hulp aan iedereen die een ongewenste seksuele ervaring heeft meegemaakt. Hiervoor kan veilig en anoniem contact worden gemaakt, telefonisch of via de chat. Centrum Seksueel Geweld Utrecht kan forensische, medische en psychische hulp inschakelen om slachtoffers van recente aanranding en verkrachting specialistische zorg te geven. Zij staan 24/7 voor je klaar om jou de hulp te bieden die je nodig hebt!"])},
    "milieudefensie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieudefensie"])},
    "milieudefensieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieudefensie is een van de meest invloedrijke klimaatorganisaties van dit moment. Zij pleiten voor een eerlijke en klimaatvriendelijke samenleving, door samenwerking van iedereen; burgers, maar ook bedrijven en de politiek. Zo heeft Milieudefensie er bijvoorbeeld voor gezorgd dat Shell moet verduurzamen van de rechter. Om er voor te zorgen dat de grote vervuilers écht bijdragen aan een oplossing, heeft Milieudefensie jouw hulp nodig!"])},
    "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie kun je vinden op"])}
  }
}