<template>
  <el-row id="goals">
    <el-col :xs="22" :sm="14" :offset="5" class="title">
      <h1>{{ $t('goals.goals') }}</h1>
    </el-col>
    <el-col :xs="22" :sm="14" :offset="5">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item :title="$t('goals.CSGU')" name="1" class="stripe">
          {{ $t('goals.CSGUtext') }}
          <br><br>
          {{ $t('goals.moreInfo') }}: <a href="https://www.centrumseksueelgeweld.nl" target="_blank">centrumseksueelgeweld.nl</a>        
        </el-collapse-item>
        <el-collapse-item :title="$t('goals.milieudefensie')" name="2" class="stripe">
          {{ $t('goals.milieudefensieText') }}
          <br><br>
          {{ $t('goals.moreInfo') }}: <a href="https://milieudefensie.nl" target="_blank">milieudefensie.nl</a>
        </el-collapse-item>
      </el-collapse>
    </el-col>
  </el-row>
</template>

  <script>
  export default {
    name: 'Goals',
  }
  </script>
