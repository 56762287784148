<template>
  <el-row id="navbar">
    <el-col :span="12">
      <a
        href="#home"
        @click="activeItem = ''"
      >
        {{ $t('navbar.charityDay') }}
      </a>
    </el-col>
    <el-col :span="12" class="menu-items">
      <a
        href="#planning"
        :class="activeItem == 'planning' ? 'active' : ''"
        @click="activeItem = 'planning'"
      >
        {{ $t('navbar.planning') }}
      </a>
      <a
        href="#goals"
        :class="activeItem == 'goals' ? 'active' : ''"
        @click="activeItem = 'goals'"
      >
        {{ $t('navbar.goals') }}
      </a>
      <img v-if="$i18n.locale == 'en'" @click="$i18n.locale = 'nl'" src="@/assets/English.webp"/>
      <img v-else @click="$i18n.locale = 'en'" src="@/assets/Dutch.webp"/>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      activeItem: "",
    }
  }
}
</script>
